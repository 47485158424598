<template>
        <el-main>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">供片系统管理方</el-breadcrumb-item>
                <el-breadcrumb-item><a>交易平台管理</a></el-breadcrumb-item>
                <el-breadcrumb-item><a href="/">版本同步记录</a></el-breadcrumb-item>
            </el-breadcrumb>
            <div class="table_top">

                <div style="float:left;">
                    <el-input placeholder="请输入内容" v-model="input" class="input-with-select" >

                        <el-button slot="append" style="background: #409eff;color: #fff;border-radius: 0 4px 4px 0;" >搜索</el-button>
                    </el-input>
                </div>

            </div>




            <template>
                <el-table :data="tableOptions.tableData" stripe border size="small"
                         header-cell-class-name="table_header"
                        cell-class-name="table_cell" style="width: 100%">
                    <el-table-column
                            prop="name"
                            label="影片名称"
                            min-width="100">
                    </el-table-column>
                    <el-table-column
                            prop="name2"
                            label="版本名称"
                            min-width="100">
                    </el-table-column>
                    <el-table-column
                            prop="time"
                            label="导出时间">
                    </el-table-column>


                    <el-table-column
                            prop="status"
                            label="是否同步">
                    </el-table-column>
                    <el-table-column
                            min-width="180"
                            label="操作">
                        <template>

                            <el-button type="warning">详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>



            <el-pagination background layout="prev, pager, next" :total="1000"></el-pagination>
        </el-main>
</template>

<script>
    export default {
        name: "Menu3.Editionlog",
        data(){
            return{
                tableData:[{
                    name: '影片名称',
                    name2: '版本名称',
                    time: '2021-11-16 11:11:56',
                    status: '已同步',
                }],
            }
        }
    }

</script>

<style scoped>
    table th a {
        background: none;
        color: #ffffff;
    }
</style>